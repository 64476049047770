import React from 'react';

import LayoutAuth from '../../components/layout/layout-auth';
import Login from '../../components/auth/login';

const LoginPage = () => {
	return (
		<LayoutAuth>
			<Login forgotPassword />
		</LayoutAuth>
	);
};

export default LoginPage;
