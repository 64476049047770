import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Router, { useRouter } from 'next/router';

import styles from './auth.module.scss';
import useLanguage from '../_hooks/useLanguage';
import FormLoader from '../forms/loader'
import ThirdpartyAuth from './thirdparty-auth';
import useAuth from '../_hooks/useAuth';
import useForm from '../_hooks/useForm';

const Login = ({ onToggle, onSuccess }) => {
	const { t } = useLanguage();

	const { findFirstErrorText } = useForm();
	const { login } = useAuth();
	const router = useRouter();
	const [calloutError, setCalloutError] = useState(null);

	const onStepSuccess = (data) => {
		if (data && data.access_token) {
			if (onSuccess) onSuccess({ action: 'login', ...data });
			login(data.access_token, onSuccess === null); // redirect back after login
		}

		return true;
	};

	// Third party

	const onThirdPartyError = (ev, e) => {
		setCalloutError(findFirstErrorText(ev));
		setTimeout(() => { setCalloutError(null); }, 15000);
	};

	return (
		<div className={styles.authWrapper}>
			<h2>{t('authLoginTitle')}</h2>
			<p>{t('authLoginText')}</p>
			{calloutError && <div className="callout compact danger">{calloutError}</div>}
			<FormLoader name="auth>login" initialState={{ username: router.query.username || null }} onSuccess={d => onStepSuccess(d)} formParameters={{ onToggle }} />
			<div className={styles.thirdpartyAuth}>
				<ThirdpartyAuth action="login" onSuccess={d => onStepSuccess(d)} onError={(ev, e) => onThirdPartyError(ev, e)} />
			</div>
		</div>
	);
};

Login.defaultProps = {
	onToggle: null,
	onSuccess: null
};

Login.propTypes = {
	onToggle: PropTypes.func,
	onSuccess: PropTypes.func
};

export default Login;
